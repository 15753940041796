<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          variant="success"
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="col-6">
                <p class="card-title-desc">ข้อมูลทั่วไป</p>
              </div>

              <div class="row">
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <code>* </code
                    ><label for="branchId">สาขาที่สั่งซื้อ:</label>

                    <multiselect
                      id="branchId"
                      v-model="part.branchId"
                      :options="localDataBranchUser"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.part.branchId.$error,
                      }"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      :disabled="this.part.status == 'APV'"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.part.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.branchId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="supId">ผู้แทนจำหน่าย:</label>

                    <multiselect
                      id="supId"
                      v-model="part.supId"
                      :options="rowSup"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.part.supId.$error,
                      }"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      :disabled="this.part.status == 'APV'"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.part.supId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.supId.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="poDate"> วันที่สั่งซื้อ:</label>
                    <!-- disabled="disabled"  -->
                    <date-picker
                      :disabled="this.part.status == 'APV'"
                      v-model="part.poDate"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                      :class="{
                        'is-invalid': submitform && $v.part.poDate.$error,
                      }"
                    ></date-picker>
                    <div
                      v-if="submitform && $v.part.poDate.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.poDate.required">{{ message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code> <label for="poDcPer"> ส่วนลด(%):</label>
                    <!-- disabled="disabled"  -->
                    <input
                      :disabled="this.part.status == 'APV'"
                      id="poDcPer"
                      v-model="poDcPer"
                      type="number"
                      class="form-control text-end"
                      value="Otto"
                      @input="calTotal()"
                      :class="{
                        'is-invalid': submitform && $v.poDcPer.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.poDcPer.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.poDcPer.required">{{ message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label>ประเภทภาษี:</label>

                    <multiselect
                      :disabled="this.part.status == 'APV'"
                      id="vatType"
                      v-model="vatType"
                      :options="optionVatType"
                      class=""
                      label="text"
                      :show-labels="false"
                      track-by="text"
                      @select="dis"
                    ></multiselect>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code> <label for="vatPer"> ภาษี(%):</label>
                    <!-- disabled="disabled"  -->
                    <input
                      :disabled="isDisabled || this.part.status == 'APV'"
                      id="vatPer"
                      v-model="part.vatPer"
                      type="number"
                      class="form-control text-end"
                      :class="{
                        'is-invalid': submitform && $v.part.vatPer.$error,
                      }"
                      @keyup.enter="calculatePo(part)"
                      @change="calculatePo(part)"
                    />
                    <div
                      v-if="submitform && $v.part.vatPer.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.vatPer.required">{{ message }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col text-end ms-1">
                    <button
                      :disabled="
                        this.part.status == 'APV' ||
                        this.part.status == 'SUBMIT'
                      "
                      type="submit"
                      class="btn btn-info ms-1"
                      @click="updatePo()"
                    >
                      บันทึกการแก้ไขข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการอะไหล่</p>
                </div>
                <div class="col-6">
                  <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.part.status === 'APV'"
                  >
                    สั่งซื้ออะไหล่แล้ว
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.part.status === 'DFT'"
                  >
                    ร่าง
                  </p>
                  <p
                    class="badge bg-danger font-size-14 float-end"
                    v-if="this.part.status === 'CAN'"
                  >
                    ยกเลิกแล้ว
                  </p>
                </div>
                <!-- <div class="col-12">
                  <code>** หมายเหตุ : กรณีแก้ไขข้อมูลอะไหล่ เช่น ราคา,จำนวน,ส่วนลด หลังแก้ไขเสร็จให้กดปุ่ม Enter เพื่อบันทึกข้อมูล</code>
                </div> -->
              </div>
              <!-- Table -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสอะไหล่</th>
                      <th scope="col">ชื่ออะไหล่</th>
                      <th scope="col">ราคา/หน่วย</th>
                      <th scope="col">จำนวน</th>
                      <th scope="col">รวม</th>
                      <th scope="col">ส่วนลด</th>
                      <th scope="col">ส่วนลดยอด</th>
                      <th scope="col">หลังลด</th>
                      <!-- <th scope="col">vat (%)</th>
                      <th scope="col">vat</th>
                      <th scope="col">สุทธิ</th> -->
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.rowPoParts.length > 0">
                    <tr v-for="(partItem, index) in rowPoParts" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ partItem.partCode }}</td>
                      <td>{{ partItem.partNameTh }}</td>
                      <td class="">
                        <input
                          v-model="partItem.pricePerOne"
                          class="form-control text-end number"
                          :disabled="part.status == 'APV'"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculatePo(partItem)"
                          @change="calculatePo(partItem)"
                        />
                      </td>

                      <td class="">
                        <input
                          :disabled="part.status == 'APV'"
                          v-model="partItem.amount"
                          type="number"
                          class="form-control text-end Dc"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculatePo(partItem)"
                          @change="updatePart(partItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          v-model="partItem.totalPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <td class="">
                        <input
                          :disabled="part.status == 'APV'"
                          v-model="partItem.dcPer"
                          class="form-control text-end Dc"
                          @keyup.enter="updatePart(partItem)"
                          @input="calculatePo(partItem)"
                          @change="calculatePo(partItem)"
                        />
                      </td>
                      <td class="text-end" @change="calculatePo(partItem)">
                        <input
                          disabled
                          v-model="partItem.dcPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <td class="text-end" @change="calculatePo(partItem)">
                        <input
                          disabled
                          v-model="partItem.netPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <!-- <td class="text-end" @change="calculatePo(partItem)">
                        <input
                          disabled
                          v-model="partItem.vatPer"
                          class="form-control text-end Dc"
                        />
                      </td>
                      <td class="text-end" @change="calculatePo(partItem)">
                        <input
                          disabled
                          v-model="partItem.vatPrice"
                          class="form-control text-end number"
                        />
                      </td>
                      <td class="text-end" @change="calculatePo(partItem)">
                        <input
                          disabled
                          v-model="partItem.grandTotalPrice"
                          class="form-control text-end number"
                        />
                      </td> -->
                      <td v-if="part.status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(partItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- search-->
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="card-title-desc">ค้นหารายการอะไหล่</p>

              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="row">
                      <span
                        v-if="this.rowPoParts.length === 0"
                        class="text-center font-size-16"
                        >ไม่พบรายการ</span
                      >
                    </div>
                    <hr />
                    <div class="row">
                      <form
                        class="needs-validation"
                        @submit.prevent="searchForm"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3 position-relative">
                              <label for="partsCode"
                                ><code>*</code>
                                ค้นหารหัสอะไหล่,ชื่ออะไหล่:</label
                              >
                              <multiselect
                                :disabled="this.part.status == 'APV'"
                                id="partsCode"
                                v-model="searchform.partsCode"
                                :options="rowParts"
                                :class="{
                                  'is-invalid': submit,
                                }"
                                label="partId"
                                 placeholder="ค้นหาชื่ออะไหล่"
                                :custom-label="customLabel"
                                :show-labels="false"
                                track-by="nameTh"
                                @input="showModal(searchform.partsCode)"
                                :loading="loading"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                @search-change="getParts($event)"
                              >
                              </multiselect>

                              <!-- @ok="handleOk" -->
                            </div>
                          </div>

                          <div></div>
                        </div>
                      </form>
                      <!-- modal -->

                      <b-modal
                        ref="modalAmout"
                        title="ระบุจำนวน"
                        hide-footer
                        size="sm"
                        centered
                        :state="nameState"
                      >
                        <form
                          class="needs-validation"
                          @submit.prevent="modalForm"
                        >
                          <div class="row">
                            <div class="col">
                              <div class="mb-3 position-relative">
                                <label for="amount"
                                  ><code>*</code> จำนวน:</label
                                >
                                <span>({{ this.part.nameTh }})</span>
                                <input
                                  :state="nameState"
                                  style="text-align: center"
                                  id="amount"
                                  v-model="part.amount"
                                  type="number"
                                  class="form-control"
                                  value="Otto"
                                  @input="calculatePo(part)"
                                  @change="calculatePo(part)"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.part.amount.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.part.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.part.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="text-end">
                                <button
                                  @click="postPoPart(part)"
                                  class="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </b-modal>
                      <!-- end model -->
                    </div>
                    <hr />
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดการสั่งซื้ออะไหล่</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">
                          รายละเอียดการสั่งซื้อ
                        </div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่าอะไหล่ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              จำนวนเงิน : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.poTotalPrice) }} <br />
                            {{ this.poDcPer }}<br />
                            {{ this.numberFormats(this.poDcPrice) }} <br />
                            {{ this.numberFormats(this.poNetPrice) }} <br />
                            {{ this.numberFormats(this.poVatPer) }} <br />
                            {{ this.numberFormats(this.poVatPrice) }}</strong
                          >
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="
                            col-md-5 col-sm-5
                            bg-dark
                            text-white
                            m-0
                            row
                            align-items-center
                          "
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(
                                      this.poGrandTotalPrice
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br /><br />
                      <div class="row mb-4 mt-4">
                        <div class="col text-end ms-1">
                          <button
                            class="btn btn-danger ms-1"
                            @click="alertSubmit()"
                            :disabled="this.part.status == 'APV'"
                          >
                            ยืนยันการสั่งซื้อ
                          </button>

                          <!-- <button
                      type="submit"
                      class="btn btn-info ms-1"
                      @click="updatePo()"
                      :disabled="this.part.status == 'APV'"
                    >
                      บันทึกการแก้ไขข้อมูล
                    </button> -->
                          <p>
                            <code v-if="this.part.status == 'APV'"
                              >***
                              ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว</code
                            >
                            <code v-if="this.part.status == 'DFT'"
                              >*** ถ้ายืนยันการสั่งซื้อแล้ว
                              จะไม่สามารถแก้ไขข้อมูลได้</code
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <!-- end col -->

    <!-- table -->

    <!-- end table -->
  </Layout>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import { _ } from "vue-underscore";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.po,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      loading: false,
      enabled: false,
      modalShow: false,
      message: "โปรดระบุข้อมูล",
      title: "แก้ไขการสั่งซื้ออะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: appConfig.po,
          href: "/purchase-order",
        },
        {
          text: "แก้ไขการสั่งซื้อ",
          active: true,
        },
      ],

      optionVatType: [
        {
          text: "ภาษีนอก(ราคายังไม่รวมภาษี)",
          id: 1,
        },
        {
          text: "ภาษีใน(ราคารวมภาษี)",
          id: 2,
        },
      ],
      check: "",
      disabled: false,
      isDisabled: false,
      poId: this.$route.params.poId,
      rowSup: [],
      partsCode: [],
      nameState: null,
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      perPage: 10,
      currentPage: 1,
      rowPoParts: [],
      rowParts: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      orderDate: "",
      branchOrder: null,
      orderType: null,
      orderCompany: null,
      orderPer: null,
      options: [],
      selectedPart: {},
      // รวมpo
      poTotalPrice: 0, // มูลค่ารวมอะไหล่
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: 0,
      poVatPer: appConfig.vat,
      poVatPrice: 0,
      poGrandTotalPrice: 0,
      vatType: "",
      part: {
        partId: "",
        partCode: "",
        nameTh: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        vatPrice: 0,
        vatPer: appConfig.vat,
        grandTotalPrice: 0, // มูลค่ารวมคิดภาษี
        branchId: "",
        supId: "",
        poDate: "",

        status: "",
      },
      searchform: {
        partsCode: "",
        backOrder: "",
      },
      submit: false,
      submitted: false,
      submitform: false,
    };
  },
  validations: {
    part: {
      amount: {
        required,
        numeric,
      },
      branchId: {
        required,
      },
      poDate: {
        required,
      },
      vatPer: {
        required,
      },
      dcPer: {
        required,
      },
      supId: {
        required,
      },
    },
  },
  computed: {},

  mounted() {},

  created() {
    this.getLocalData();
    this.getSup();
    this.getPoShow();
    this.getPoPart();
  },
  methods: {
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    dis(option) {
      if (option.id === 2) {
        this.part.vatPer = 0;
        this.isDisabled = true;
      } else if (option.id === 1 || option == null) {
        this.part.vatPer = 7;
        this.isDisabled = false;
      }
    },
    calculatePo(part) {
      let totalPriceCal = parseFloat(part.pricePerOne * part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }

      var DcPricecal = 0;
      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      if (this.vatType.id === 1) {
        calNetPrice = parseFloat(part.totalPrice - part.dcPrice);
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice.toFixed(2);
        }
      } else if (this.vatType.id === 2) {
        calNetPrice = parseFloat(part.totalPrice * parseFloat(100 / 107));
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice;
        }
      } else {
        calNetPrice = parseFloat(part.totalPrice - part.dcPrice);
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice.toFixed(2);
        }
      }

      let calVatPrice = 0;

      if (this.vatType.id === 1) {
        calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      } else if (this.vatType.id === 2) {
        calVatPrice = parseFloat(part.totalPrice - part.netPrice);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      } else {
        calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      }

      let calGrandTotalPrice = 0;
      if (this.vatType.id === 1) {
        calGrandTotalPrice =
          parseFloat(part.netPrice) + parseFloat(part.vatPrice);
        if (!isNaN(calGrandTotalPrice)) {
          part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
        }
      } else if (this.vatType.id === 2) {
        part.grandTotalPrice = parseFloat(part.totalPrice - part.dcPrice);
      } else {
        calGrandTotalPrice =
          parseFloat(part.netPrice) + parseFloat(part.vatPrice);
        if (!isNaN(calGrandTotalPrice)) {
          part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
        }
      }
      // part.totalPrice = this.numberFormats(part.totalPrice);
      // part.dcPrice = this.numberFormats(part.dcPrice);
      // part.netPrice = this.numberFormats(part.netPrice);
      // part.vatPrice = this.numberFormats(part.vatPrice);
      // part.grandTotalPrice = this.numberFormats(part.grandTotalPrice);
      this.calTotal();
    },
    calTotal() {
      let poTotalPrice, poDcPrice, poNetPrice, poVatPrice, poGrandTotalPrice;

      poTotalPrice = this.rowPoParts.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.poTotalPrice = poTotalPrice.toFixed(2);
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice.toFixed(2);
      }
      poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice.toFixed(2);
      }

      poVatPrice = this.poNetPrice * parseFloat(this.poVatPer / 100);
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice.toFixed(2);
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice.toFixed(2);
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
    },
    showModal(option) {
      this.selectedPart = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice;
      this.part.totalPrice = option.sellPrice;
      this.part.netPrice = option.sellPrice;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
    },

    customLabel({ partCode, nameTh }) {
      return `[${partCode}] - ${nameTh != null ? nameTh : ""}`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowTest = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowTest) {
      this.selected = rowTest;
      // this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // eslint-disable-next-line no-unused-vars
    modalForm() {
      this.$v.$touch();
      this.submitted = true;
    },
    searchForm() {
      this.$v.$touch();
      this.submit = true;
    },

    postPoPart: function (part) {
      // console.log(this.rowPoParts);
      let arr = [];
      this.rowPoParts.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.rowPoParts.push(part);
      }
      this.calculatePo(part);
      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = parseFloat(this.selectedPart.sellPrice) * part.amount;
      part.netPrice;

      const formDataPart = new FormData();
      formDataPart.append("poId", this.poId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", part.amount);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.dcPrice);
      formDataPart.append("dcPer", part.dcPer);
      formDataPart.append("netPrice", part.netPrice);
      formDataPart.append("vatPer", part.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append(
        "grandTotalPrice",
        part.grandTotalPrice.replace(/,/g, "")
      );
      formDataPart.append("poTotalPrice", this.poTotalPrice);
      formDataPart.append("poDcPer", this.poDcPer);
      formDataPart.append("poDcPrice", this.poDcPrice);
      formDataPart.append("poNetPrice", this.poNetPrice);
      formDataPart.append("poVatPer", this.poVatPer);
      formDataPart.append("poVatPrice", this.poVatPrice);
      formDataPart.append("poGrandTotalPrice", this.poGrandTotalPrice);

      useNetw
        .post("api/part-po/part/store", formDataPart)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.searchform.partsCode = "";
          this.$refs["modalAmout"].hide();
          // this.getPoPart(this.poId);
          this.getPoShow(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    putSubmit() {
      useNetw
        .put("api/part-po/submit", {
          poId: this.poId,
        })

        .then((response) => {
          this.calTotal();
          this.getPoPart(this.poId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getPoShow(this.poId);
          this.$router.push({ name: "purchase-order" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    updatePart(part) {
      this.calTotal();
      this.overlayFlag = true;
      useNetw
        .put("api/part-po/part/update", {
          poId: this.poId,
          partId: part.partId,
          amount: part.amount,
          pricePerOne: part.pricePerOne,
          totalPrice: part.totalPrice,
          dcPer: part.dcPer,
          dcPrice: part.dcPrice,
          netPrice: part.netPrice,
          vatPer: part.vatPer,
          vatPrice: part.vatPrice,
          grandTotalPrice: part.grandTotalPrice,
          poTotalPrice: this.poTotalPrice,
          poDcPer: this.poDcPer,
          poDcPrice: this.poDcPrice,
          poNetPrice: this.poNetPrice,
          poVatPer: this.poVatPer,
          poVatPrice: this.poVatPrice,
          poGrandTotalPrice: this.poGrandTotalPrice,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          this.getPoShow(this.poId);
          this.calTotal();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updatePo() {
      useNetw
        .put("api/part-po/update", {
          poId: this.poId,
          supId: this.part.supId.supId != null ? this.part.supId.supId : "",
          poDate: this.part.poDate,
          totalPrice: this.poTotalPrice,
          dcPer: this.poDcPer,
          dcPrice: this.poDcPrice,
          netPrice: this.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poVatPrice,
          grandTotalPrice: this.poGrandTotalPrice,
        })

        .then((response) => {
          this.calTotal();
          this.getPoPart(this.poId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    getPoShow: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/part-po/show", {
          params: {
            poId: this.poId,
          },
        })
        .then((response) => {
          this.branchIdPo = response.data.data.branchId;
          this.rowRoShow = response.data.data;
          this.part.status = response.data.data.status;
          this.part.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.part.supId = {
            supId: response.data.data.supId,
            nameTh: response.data.data.supplierNameTh,
          };
          this.part.poDate = response.data.data.poDate;
          this.poDcPer = response.data.data.dcPer;
          this.poDcPrice = response.data.data.dcPrice;
          this.poTotalPrice = response.data.data.totalPrice;
          this.poNetPrice = response.data.data.netPrice;
          this.poVatPer = response.data.data.vatPer;
          this.poVatPrice = response.data.data.vatPrice;
          this.poGrandTotalPrice = response.data.data.grandTotalPrice;
          this.calTotal();
          this.getPoPart(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getParts: function (event) {
      // this.overlayFlag = true;
      useNetw
        .get("api/part-po/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdPo],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getSup: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.rowSup = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getPoPart: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-po/parts", {
          params: {
            poId: this.poId,
          },
        })
        .then((response) => {
          this.rowPoParts = response.data.data;

          this.calTotal();
          // console.log("getPo", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    deletePoPart: function (rowPoItem, index) {
      this.rowPoParts.splice(index, 1);
      this.calTotal(this.rowPoParts);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-po/part/delete", {
          data: {
            partId: rowPoItem.partId,
            poId: this.poId,
            poTotalPrice: this.poTotalPrice,
            poDcPer: this.poDcPer,
            poDcPrice: this.poDcPrice,
            poNetPrice: this.poNetPrice,
            poVatPer: this.poVatPer,
            poVatPrice: this.poVatPrice,
            poGrandTotalPrice: this.poGrandTotalPrice,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getPoShow(this.poId);
          // this.rowPoParts.push(this.rowPoArr)
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowPoItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePoPart(rowPoItem, index);
          }
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการสั่งซื้อหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 250px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

